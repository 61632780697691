<template>
  <main class="view">
    <div class="scan-wrapper">
      <div class="col col-7 sticky top margin-bottom-auto">
        <label class="label" for="order-barcode">
          <span class="label__span">
            <p>{{ $t('Scan order barcode') }}</p>
            <button class="button icon action-toggle dropdown" style="min-width: 115px;" @click.prevent="$event.target.classList.toggle('visible')">
              <span class="icon-inline barcode-scanner" style="background-color: white; margin-right: 1em;" />
              {{ $t('Configuration') }}
              <ul class="button-actions">
                <li>
                  <a @click="PrintScannerBarcodes('deltaco_dur-798'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">DELTACO DUR-798</a>
                </li>
                <li>
                  <a @click="PrintScannerBarcodes('symbol_ls2208'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">SYMBOL LS2208</a>
                </li>
                <li>
                  <a @click="PrintScannerBarcodes('tysso_gs-6010'), $event.target.closest('.action-toggle').classList.remove('visible')" href="">TYSSO GS-6010</a>
                </li>
              </ul>
              <span class="button-icon caret-down" />
            </button>
          </span>
          <input name="order-barcode" class="label__input" type="text" spellcheck="false">
        </label>
        <div class="scan-status">
          <div :class="scan.status.type"><b>{{ $t(scan.status.message) }}</b></div>
        </div>
        <div class="counter grid">
          <div style="display: flex;">
            <label v-if="AllowDisableCache()" :style="{display: 'flex', alignItems: 'center', cursor: AllowDisableCache() && 'pointer'}">
              <div class="toggle">
                <div class="checkbox-toggle">
                  <input type="checkbox" v-model="disable_cache" :disabled="!AllowDisableCache()">
                  <span class="toggle" />
                </div>
              </div>
              <span style="margin-left: 10px; line-height: 1.2;">
                {{ $t('Disable cache') }}
              </span>
            </label>
          </div>
          <span id="timer" :class="['timer font', GetColor(seconds)]">
            <p>{{ GetTimer(seconds) }}</p>
          </span>
        </div>
        <!-- <img ref="dvr.image"> -->
      </div>
      <div class="col col-5">
        <div class="row text-center">
          <div class="col col-6" style="display: none;">
            <span>Salary earned:</span>
            <p class="number">1500</p>
          </div>
          <div class="col col-6" style="width: 100%;">
            <span style="font-weight: 600;">{{ $t('Parcels') }}</span>
            <p class="number">{{ packages.list.length }}</p>
          </div>
        </div>
        <ol class="order-list">
          <li class="order-list__head">
            <span class="col-5">{{ $t('Order number') }}</span>
            <span class="col-3">{{ $t('Time') }}</span>
            <span class="col-2">{{ $t('Products') }}</span>
            <span class="col-1"></span>
          </li>
          <li class="order-list__body" :key="index" v-for="(item, index) in GetPaginatedList()">
            <span class="col-5">{{ item.id }}</span>
            <span :class="['col-3 font', GetColor(item.seconds)]">{{ GetTimer(item.seconds) }}</span>
            <span class="col-2">{{ item.scanned }}</span>
            <span class="col-1"></span>
          </li>
          <li class="order-list__pagination" v-if="packages.pages > 1">
            <span class="arrow">
              <a @click.prevent="SetPage(null, -1)" href="">&#11207;</a>
            </span>
            <span :class="{current: currentPage == index - 1}" :key="index" v-for="index in packages.pages">
              <a @click.prevent="SetPage(index - 1)" href="">{{ index }}</a>
            </span>
            <span class="arrow">
              <a @click.prevent="SetPage(null, 1)" href="">&#11208;</a>
            </span>
          </li>
        </ol>
      </div>
    </div>
    <div id="validate-popup" class="validate popup" @click="ClosePopup">
      <div class="col col-10 popup-view">
        <div class="popup-header">
          <div class="popup-title flex-column">
            <p class="view-title">{{ /*order.id*/ order.order_id }}</p>
            <div class="company-logo" :style="OrderCompanyLogo"></div>
          </div>
          <div class="col col-6 align-center" style="text-align: center;">
            <p>{{ $t('Scan product barcode') }}</p>
            <input class="product-input" type="text" name="product-barcode" ref="barcode_input" spellcheck="false">
            <div class="shipping-method" v-if="order.shipping">
              <div :class="['shipping-method__courier courier-icon', order.shipping.agent_code]"></div>
              <div class="shipping-method__description">{{ order.shipping.shipping_method }}</div>
            </div>
          </div>
          <div class="popup-close flex-column">
            <div class="flex-row">
              <div class="counter">
                <span id="popup-timer" :class="['timer font', GetColor(seconds)]">
                  <p>{{ GetTimer(seconds) }}</p>
                </span>
              </div>
              <div class="close">
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="order-crate" :style="OrderCrateImage"></div>
          </div>
        </div>
        <div class="row check-list-container">
          <ol class="check-list to-do col-6">
            <li class="check-list__row" :data-id="row.id" v-bind:key="row.id" v-for="row in order.items">
              <div class="row-image">
                <img :src="row.image">
              </div>
              <div :class="['row-name', {yellow: row.scanned > 0 && row.scanned < row.amount, green: row.scanned == row.amount}]">
                <span><b>{{ row.remaining }}</b> <small>x</small> {{ row.name }}</span>
                <ul class="ok-barcode-list" v-if="row.ok.length">
                  <li v-for="barcode in row.ok" :key="barcode">{{ barcode }}</li>
                </ul>
                <span v-if="scan.show.sku" :class="[{hidden: scan.show.hidden}]">
                  <small class="dblclick" @dblclick="DblClick(row.id)">{{ row.id }}</small>
                </span>
              </div>
            </li>
          </ol>
          <!-- <span class="spacer"><b>&#129094;</b></span> -->
          <span :class="['spacer', {'super-user': scan.show.su}]">
            <b class="arrow-thick" @click="OneClickComplete" />
          </span>
          <ol id="check-list-done" class="check-list col-6">
            <li :class="['check-list__row', {placeholder: row.placeholder}]" :data-id="row.id" v-bind:key="row.id" v-for="row in order.scanned">
              <div class="row-image">
                <img :src="row.image">
              </div>
              <div :class="['row-name', {yellow: row.scanned > 0 && row.scanned < row.amount, green: row.scanned == row.amount}]">
                <span><b>{{ row.scanned }}</b> <small>x</small> {{ row.name }}</span>
                <ul class="ok-barcode-list" v-if="row.ok.length">
                  <li v-for="barcode in row.ok" :key="barcode">{{ barcode }}</li>
                </ul>
                <span v-if="scan.show.sku"><small>{{ row.id }}</small></span>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="log" v-if="log.handler.display" @click="HandleLog">
      <div class="log-popup">
        <div class="log-popup__top">
          <div class="log-popup__top---heading label" style="width: 100%; display: flex; flex-direction: row;">
            <h3>{{ log.title }}</h3>
            <input class="v-input" style="width: 60%; margin: auto;" />
          </div>
          <div class="log-popup__top--close-btn"></div>
        </div>
        <div class="log-popup__content">
          <pre>{{ log.content }}</pre>
        </div>
      </div>
    </div>
    <!--
    <div class="blacklist" v-if="blacklist.blockage" @click="HandleBlockage">
      <div class="blockage">
        <div class="content" v-html="blacklist.message" />
        <button class="button green">Okay, I understand</button>
      </div>
    </div>
    -->
    <div v-if="check_tnt_barcode.show_popup">
      <transition name="fade">
        <div class="tnt-check-backdrop">
          <div class="tnt-check-container">
            <h3 class="tnt-check-heading">{{ $t('Scan tracking label') }}</h3>
            <div v-if="check_tnt_barcode.error.active" class="tnt-check-error">{{ $t(check_tnt_barcode.error.message) }}</div>
            <input ref="tntCheckInput" class="tnt-check-input" type="text" v-model="check_tnt_barcode.barcode" @change="MatchTnTBarcode()">
          </div>
        </div>
      </transition>
    </div>
  </main>
</template>

<script>
  import { BPA } from '@/helpers/BPA';
  import { Tool } from '@/helpers/Tool';
  import { Config } from '@/helpers/Config';
  import { Permissions } from '@/helpers/Permissions';
  //import socketio from 'socket.io-client';

  export default {
    name: 'Scan',
    mixins: [Permissions, BPA, Tool],
    components: {},
    data() {
      return {
        isRunning: false,
        counter: null,
        seconds: 0,
        colors: {
          100: 'red',
          80: 'yellow',
          60: 'green'
        },
        maxSeconds: 120,
        fallbackColor: 'red',
        maxListAmount: 10,
        currentPage: 0,
        packages: {
          pages: null,
          list: []
        },
        OrderCompanyLogo: '--logo: url();',
        OrderCrateImage: '--image: url();',
        isScanning: false,
        disable_cache: false,
        uid: {
          promises: {},
          list: {},
          check: {
            interval: 60000,
            timer: null,
            list: {}
          }
        },
        pin: null,
        scan: {
          alert: false,
          newline: false,
          input: {
            keys: [],
            prev: {}
          },
          status: {
            type: 'ok',
            message: 'Ready'
          },
          show: {
            hidden: false,
            sku: false,
            su: false
          }
        },
        order: {
          id: null,
          items: [],
          scanned: [],
          overview_id: null
        },
        recording: {
          started: false,
          ended: false,
          error: '',
          dvr: {
            host: '',
            start: '',
            stop: ''
          },
          timeout: {
            time: {
              ms: 600000, // 10 min
            },
            timer: {}
          }
        },
        handler: {
          keydown: null,
          keyup: null
        },
        previous: {
          id: null,
          barcode: null
        },
        log: {
          title: 'Server response log',
          status: {},
          content: '',
          handler: {
            display: false,
            keydown: null
          }
        },
        loading: false,
        swip_box: {
          is_swip_box: false,
          order: {},
        },
        check_tnt_barcode: {
          barcode : '',
          will_show_popup: false,
          show_popup: false,
          is_correct: false,
          error: {
            active: false,
            message: ''
          },
          start_scan: null,
        },
        /*
        blacklist: {
          blockage: false,
          message: '',
          product: {
            box: [
              '7391681036543',
              '7391681036536',
              '7391681036147',
              '7391681036345',
              '7391681036130',
              '7391681036338',
              '7391681036437',
              '7391681036444',
              '7391681036048',
              '7391681036031',
              '7391681036246',
              '7391681036239'
            ]
          }
        }
        */
        /*
        socket: socketio(BPA.host, {
          transportOptions: {
            polling: {
              extraHeaders: {
                'authorization': BPA.session.auth().token,
                'company-code': BPA.util.GetCompany(),
              }
            }
          }
        })
        */
      }
    },
    watch: {
      'check_tnt_barcode.show_popup'(newVal) {
        if(newVal) {
          this.$nextTick(() => {
            this.$refs.tntCheckInput.addEventListener('keydown', this.HandleTnTBarcodePress)
            this.$refs.tntCheckInput.onpaste = (e) => {
              e.preventDefault();
            }
          })
        }
      }
    },
    async created() {
      this.recording.timeout.time.remaining = this.recording.timeout.time.ms;
      this.recording.timeout.sec = this.recording.timeout.ms / 1000;

      let duration = this.recording.timeout.time.remaining;
      let now = Date.now;
      let end = 0;
      
      this.recording.timeout.timer = {id: requestAnimationFrame.bind(window)};

      this.recording.timeout.timer.run = async () => {
        let current = end - now();
        let remaining = duration;
        if (this.recording.timeout.timer.id) {
          if (current > 0) {
            remaining = (this.recording.timeout.timer.id(this.recording.timeout.timer.run), current);
          } else {
            await this.recording.timeout.timer.stop();
          }
        }
        this.recording.timeout.time.remaining = remaining;
      }

      this.recording.timeout.timer.start = () => {
        if (this.recording.timeout.timer.id) {
          end = now() + duration;
          this.recording.timeout.timer.id(this.recording.timeout.timer.run);
        }
      }

      this.recording.timeout.timer.restart = () => {
        this.recording.timeout.timer.id = requestAnimationFrame.bind(window);
        this.recording.timeout.timer.start();
      }

      this.recording.timeout.timer.stop = async (stop = true) => {
        cancelAnimationFrame(this.recording.timeout.timer.id);
        this.recording.timeout.timer.id = null;
        if (!this.recording.ended && stop) {
          await this.StopVideoRecording({order_id: this.previous.id || this.order.overview_id});
        }
      }

      /*
      ['connect', 'PRODUCT_UNIQUE_IDENTIFIER__UPDATE_CHECK'].map(event => {
        this.socket.on(event, (e = {}) => {
          switch (event) {
            case 'connect':
              this.socket.emit('join', 'product_unique_identifier');
              break;
            case 'PRODUCT_UNIQUE_IDENTIFIER__UPDATE_CHECK':
              var data = {
                event: e.data.opcode.toLowerCase(),
                company: e.data.res_company_code,
                barcode: e.data.target_barcode,
                regex: e.data.regex_check
              }
              if (data.event == 'create') {
                this.uid.check.list[data.company][data.barcode] = data.regex;
              }
              if (data.event == 'delete') {
                delete this.uid.check.list[data.company][data.barcode];
              }
          }
        });
      });
      */
      /*
      const packages = [];
      let increment = 100000000;
      let seconds = 10;
      let scanned = 1;
      for (let i = 0; i < 100; i++) {
        increment++;
        seconds++;
        scanned++;
        packages[i] = {
          id:'1:' + increment,
          seconds: seconds,
          scanned: scanned
        }
      }
      BPA.storage.setItem('packages', JSON.stringify(packages.reverse()));
      */
    },
    unmounted() {
      // I think you meant to use the destroyed hook :)
    },
    async mounted() {
      let host = BPA.dvr.fetch('host') || '';
      let start = BPA.dvr.fetch('start') || '';
      let stop = BPA.dvr.fetch('stop') || '';
      let dvr = this.recording.dvr;
      dvr.image = new Image();
      dvr.image.crossOrigin = 'anonymous';
      dvr.host = host.replace(/\/$/, '');
      dvr.start = dvr.host + '/' + start.replace(/^\//, '');
      dvr.stop = dvr.host + '/' + stop.replace(/^\//, '');
      dvr.stream = {url: dvr.host + '/stream.mjpg'};
      dvr.stream.check = async () => {
        if (!dvr.image || !dvr.host) return true;
        return await new Promise((resolve, reject) => {
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          //let dvr.image = this.$refs.dvr.image;
          let all_black = () => {
            let image = context.getImageData(0, 0, canvas.width, canvas.height);
            let buffer = new Uint32Array(image.data.buffer);
            return !buffer.some(color => color !== 0);
          }
          let error = {
            message: 'The DVR stream failed - Please ensure that DVR service is running', 
            occured: false, 
            state: false
          };
          let loaded = false;
          dvr.image.onload = () => {
            if (loaded) return;
            loaded = true;
            canvas.width = dvr.image.naturalWidth;
            canvas.height = dvr.image.naturalHeight;
            context.drawImage(dvr.image, 0, 0);
            if (dvr.image.complete) {
              dvr.image.src = canvas.toDataURL();
              if (canvas.width + canvas.height == 0) {
                error.message = 'No image from DVR stream - Please check DVR service and camera module';
                error.occured = true;
              } else if (all_black()) {
                error.message = 'Image from DVR stream is all black - Please check camera module';
                error.occured = true;
              }
              if (error.occured && !error.state) return dvr.image.onerror();
              resolve(true);
            }
          }
          dvr.image.onerror = () => {
            error.state = true;
            this.$eventHub.$emit('ShowMessages', {
              message: error.message,
              type: 'error',
              close: true
            });
            reject();
          }
          dvr.image.onabort = (e) => {
            console.warn(e)
          }
          dvr.image.src = dvr.stream.url;
        }).catch(e => e);
      }
      //if (dvr.host) await dvr.stream.check();
      const props = ['multi', 'combo'];
      const key = {multi: false, combo: ''};
      const pressing = () => {
        let keys = 0;
        for (var event in key) {
          if (!props.includes(event)) {
            if (key[event]) {
              if (key.combo.length) {
                key.combo += '+';
              }
              key.combo += event;
              if (++keys > 1) {
                return true;
              }
            }
          }
        }
        key.combo = '';
        return false;
      }
      const keymapper = (e) => {
        var char = e.key.toLowerCase();
        key[char] = e.type == 'keydown';
        key.multi = pressing();
      }
      const strip = (string) => {
        return string.replace(/\s\s+/g,' ').trim();
      }
      this.handler.keydown = (e) => {
        if (document.querySelector('dialog[open]')) {
          return window.removeEventListener('keydown', this.handler.keydown);
        }
        if (this.check_tnt_barcode.show_popup) {
          return window.removeEventListener('keydown', this.handler.keydown);
        }
        if (key[e.key] && e.key != ':' || this.scan.alert) {
          return e.preventDefault();
        }
        keymapper(e);
        const character = e.key;
        const keycode = e.keyCode;
        const focused = document.activeElement;
        const blocked = ['Tab','CapsLock','Shift','Control','Meta','Alt','Space','AltGraph','ContextMenu','Enter','Backspace','Delete','ArrowLeft','ArrowUp','ArrowRight','ArrowDown'];
        const comboes = ['a','c','v','x','z'];
        const allowed = (keycode > 47 && keycode < 58)     || // number keys
                        (keycode > 64 && keycode < 91)     || // letter keys
                        (keycode > 95 && keycode < 112)    || // numpad keys
                        (keycode == 8  || keycode == 46)   || // Backspace or Delete
                        (keycode == 16 || keycode == 17)   || // Shift or Control
                        ([37, 38, 39, 40].includes(keycode)); // Arrow keys
        if (!this.isScanning) {
          if (this.scan.newline) {
            this.scan.newline = false;
            //this.input.order.value = '';
          }
          if (this.input.order == focused) {
            if ((!allowed || keycode == 32) && !(keycode == 190 || keycode == 192)) {
              if (!comboes.includes(key.combo.split('+')[1])) e.preventDefault();
            }
            return;
          }
          if ((!allowed || keycode == 32) && !( keycode == 190 || keycode == 192)) return;
          if (key.multi) {
            if (e.getModifierState('Control') && character == 'v') {
              this.input.order.value = '';
              this.input.order.focus();
              setTimeout(() => {
                this.input.order.value = strip(this.input.order.value);
                this.input.order.blur();
              });
              return;
            }
            if (comboes.includes(key.combo.split('+')[1])) return;
          }
          if (character == 'Backspace') {
            this.input.order.value = this.input.order.value.slice(0, -1);
          }
          if (blocked.includes(character)) return;
          if (e.getModifierState('Control')) return;
          if (e.getModifierState('AltGraph')) return;
          this.input.order.value += character/*.replace(/æ|~/i, ':')*/;
        } else {
          if (this.scan.newline) {
            this.scan.newline = false;
            this.input.product.value = '';
          }
          if (this.input.product == focused) {
            if (e.getModifierState('AltGraph')) {
              if (/s|ß/i.test(character)) {
                if (this.scan.show.hidden) {
                  this.scan.show.hidden = false;
                }
                this.scan.show.sku = true;
              }
              if (this.IsSuperUser()) {
                if (/u|↓/i.test(character)) {
                  if (!this.scan.show.sku) {
                    this.scan.show.sku = true;
                    this.scan.show.hidden = true;
                  }
                  this.scan.show.su = true;
                }
              }
            }
            if (!allowed || keycode == 32) {
              e.preventDefault();
            }
            return;
          }
          if (!allowed || keycode == 32) return;
          if (key.multi) {
            if (e.getModifierState('Control') && character == 'v') {
              this.input.product.value = '';
              this.input.product.focus();
              setTimeout(() => {
                this.input.product.value = strip(this.input.product.value);
                this.input.product.blur();
              });
              return;
            }
            if (e.getModifierState('AltGraph')) {
              if (/s|ß/i.test(character)) {
                if (this.scan.show.hidden) {
                  this.scan.show.hidden = false;
                }
                this.scan.show.sku = true;
              }
              if (this.IsSuperUser()) {
                if (/u|↓/i.test(character)) {
                  if (!this.scan.show.sku) {
                    this.scan.show.sku = true;
                    this.scan.show.hidden = true;
                  }
                  this.scan.show.su = true;
                }
              }
            }
            if (comboes.includes(key.combo.split('+')[1])) return;
          }
          if (character == 'Backspace') {
            this.input.product.value = this.input.product.value.slice(0, -1);
          }
          if (blocked.includes(character)) return;
          if (e.getModifierState('Control')) return;
          if (e.getModifierState('AltGraph')) return;
          this.input.product.value += character;
        }
      }
      this.handler.keyup = async (e) => {
        if (!document.querySelector('dialog[open]')) {
          window.addEventListener('keydown', this.handler.keydown);
        }
        if (this.scan.alert) return;
        keymapper(e);
        if (e.keyCode == 13) {
          this.scan.newline = true;
          if (!this.isScanning) {
            this.StartPacking();
          } else {
            this.ApproveScan();
          }
        }
        /*
        if (e.keyCode == 27) {
          if (this.isScanning) {
            this.ClosePopup(e);
          }
        }
        */
      }
      this.log.handler.keydown = (e) => {
        const alert = document.querySelector('.alert-popup');
        if ((e.getModifierState('AltGraph') && e.key == 'l') && !alert) {
          this.log.handler.display = true;
          this.UnmountKeyEventHandlers();
        }
        if (e.key == 'Escape') {
          /*
          if (this.blacklist.blockage) {
            this.blacklist.blockage = false;
            this.MountKeyEventHandlers();
            return;
          }
          */
          if (this.log.handler.display) {
            this.log.handler.display = false;
            this.MountKeyEventHandlers();
            return;
          }
          if (this.isScanning && !alert) {
            this.ClosePopup(e);
          }
        }
      }
      this.MountKeyEventHandlers();
      this.MountLogKeyEventHandlers();

      this.DisableF5 = (e) => {
        if (e.keyCode == 116) {
          e.preventDefault();
          this.$eventHub.$emit('ShowMessages', {
            message: 'F5-key is disabled',
            type: 'warning',
            hide: 2000
          });
        }
      }
      document.addEventListener('keydown', this.DisableF5);

      window.onunload = async () => {
        document.removeEventListener('keydown', this.DisableF5);
        let previous_id = this.previous?.id || this.order?.overview_id;
        let previous_barcode = this.previous?.barcode || this.order?.id;
        if (previous_id) this.StopVideoRecording({order_id: previous_id, keepalive: true});
        if (previous_barcode) BPA.api.ClosePackingOrder({order_id: previous_barcode, keepalive: true});
      }
    },
    beforeRouteEnter(to, from, next) {
      next(async vm => {
        if (from.name == 'login' && !(await BPA.api.GetCurrentPunch().then(response => {
          return BPA.api.response({response, error: () => response});
        }).then(response => response.ok).catch(e => e))) {
          return next('punchclock');
        }
        vm.packages = vm.GetPackages();
        //vm.SetPage(vm.packages.pages - 1);
        vm.SetPage(0);
        BPA.api.Companies('GET').map(company => {
          vm.uid.check.list[company.code] = {};
        });
        vm.GetProductUniqueIdentifierChecks();
        next();
      });
    },
    async beforeRouteLeave(to, from, next) {
      window.onbeforeunload = null;
      document.removeEventListener('keydown', this.DisableF5);
      let previous_id = this.previous?.id || this.order?.overview_id;
      let previous_barcode = this.previous?.barcode || this.order?.id;
      if (previous_id) this.StopVideoRecording({order_id: previous_id, keepalive: true});
      if (previous_barcode) BPA.api.ClosePackingOrder({order_id: previous_barcode, keepalive: true});
      next();
    },
    destroyed() {
      this.UnmountKeyEventHandlers();
      this.UnmountLogKeyEventHandlers();
      //this.socket.emit('leave', 'product_unique_identifier');
      //this.socket.disconnect();
      clearTimeout(this.uid.check.timer);
      this.recording.timeout.timer.stop();
    },
    computed: {
      popup() {
        return document.getElementById('validate-popup');
      },
      arrow() {
        return this.popup.querySelector('.spacer .arrow-thick');
      },
      input() {
        const inputs = {};
        document.querySelectorAll('input[name$=barcode]').forEach(input => {
          inputs[input.name.split('-')[0]] = input;
        });
        return inputs;
      }
    },
    methods: {
      MountKeyEventHandlers() {
        window.addEventListener('keydown', this.handler.keydown);
        window.addEventListener('keyup', this.handler.keyup);
      },
      UnmountKeyEventHandlers() {
        window.removeEventListener('keydown', this.handler.keydown);
        window.removeEventListener('keyup', this.handler.keyup);
      },
      MountLogKeyEventHandlers() {
        window.addEventListener('keydown', this.log.handler.keydown);
      },
      UnmountLogKeyEventHandlers() {
        window.removeEventListener('keydown', this.log.handler.keydown);
      },
      LogSeverResponse(response = {}) {
        if (!response.status) return;
        const br = '\n';
        const time = new Date().toLocaleTimeString(Tool.Locale()).replace(/\./g, ':');
        let log_entry = time + br + response.status + ': ' + BPA.http(response.status);
        if (response.url) {
          log_entry += br + BPA.api.decode(response.url);
        }
        if (response.responseText) {
          log_entry += br + response.responseText;
        }
        if (this.log.content.length) {
          log_entry = br + '--- ' + br + log_entry;
        } 
        this.log.content += log_entry;
      },
      HandleLog(e) {
        const clicked = e.target;
        if (clicked && (clicked.className == 'log' || /close-btn/.test(clicked.className))) {
          this.log.handler.display = false;
          this.MountKeyEventHandlers();
        }
      },
      CompanyID(company_code) {
        return BPA.api.Companies('GET').find(company => company.code == company_code).id;
      },
      CompanyCode(company_id) {
        return BPA.api.Companies('GET').find(company => company.id == company_id).code;
      },
      CloneObject(object = {}) {
        return JSON.parse(JSON.stringify(object));
      },
      async GetPackingRequirePasswordPin() {
        return await BPA.api.GetPackingRequirePasswordPin().then(response => {
					return BPA.api.response({response, return: 'json', error(){}});
				}).then(response => (response.result || {}).pin).catch(e => e);
      },
      async GetProductUniqueIdentifierChecks() {
        clearTimeout(this.uid.check.timer);
				await BPA.api.GetProductUniqueIdentifierChecks().then(response => {
					return BPA.api.response({response, return: 'json'});
				}).then(response => {
					if (!response.ok || !response.result) return;
          let list = response.result || {};
					for (let company in list) {
            this.uid.check.list[company] = list[company];
          }
          this.uid.check.timer = setTimeout(() => {
            this.GetProductUniqueIdentifierChecks();
          }, this.uid.check.interval);
				});
      },
      async CurrentPunch() {
        return BPA.api.GetCurrentPunch().then(response => {
          return BPA.api.response({response, return: 'json', error: () => response});
        }).then(response => response.result).catch(error => error);
      },
      async ForceCommissionPunch(punch = {}) {
        return await new Promise((resolve, reject) => {
          if (punch.type == 'hourly') {
            BPA.api.PunchOut(punch.id).then(response => {
              return BPA.api.response({response, 
                return: () => {
                  BPA.api.PunchIn().then(response => {
                    return BPA.api.response({response, 
                      return: () => {
                        resolve(response);
                        this.$eventHub.$emit('ShowMessages', {
                          message: 'You are on paid commission now',
                          type: 'warning',
                          hide: 5000
                        });
                      },
                      error: (message) => {
                        reject(message);
                        this.LogSeverResponse(response);
                        this.$eventHub.$emit('ShowMessages', {
                          message: message,
                          type: 'error',
                          hide: true
                        });
                      }
                    });
                  });
                },
                error: (message) => {
                  reject(message);
                  this.LogSeverResponse(response);
                  this.$eventHub.$emit('ShowMessages', {
                    message: message,
                    type: 'error',
                    hide: true
                  });
                }
              });
            });
          } else resolve(true);
        }).catch(e => e);
      },
      async StartPackingOrder(barcode) {
        return await new Promise(async (resolve, reject) => {
          if (!await this.StartVideoRecording()) return reject();
          BPA.api.StartPackingOrder(barcode).then(response => {
            return BPA.api.response({response, 
              return: () => {
                resolve(response)
              },
              error: (message) => {
                this.LogSeverResponse(response);
                this.loading = false;
                if (response && response.status == 409) {
                  return resolve(response);
                }
                reject(message);
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  hide: true
                });
              }
            });
          });
        }).catch(e => e);
      },
      async StartVideoRecording() {
        let recording = this.recording;
        let dvr = recording.dvr;
        let timeout = recording.timeout;
        let previous_id = this.previous?.id || this.order?.overview_id;
        if (!dvr.host || !dvr.start) return true;
        if (!recording.ended && previous_id) {
          await this.StopVideoRecording({order_id: previous_id});
        }
        recording.started = false;
        recording.ended = false;
        recording.error = '';
        return await window.fetch(dvr.start, {mode: 'no-cors'}).then(() => {
          recording.started = true;
          recording.ended = false;
          recording.error = '';
          timeout.timer.start();
          return true;
        }).catch(() => {
          recording.started = false;
          recording.ended = false;
          recording.error = 'start';
          this.$eventHub.$emit('ShowMessages', {
            message: 'Video recording failed to start - Please check DVR configuration',
            type: 'error',
            close: true
          });
          return false;
        });
      },
      async StopVideoRecording(params = {}) {
        params = this.CloneObject(params);
        let recording = this.recording;
        let dvr = recording.dvr;
        let timeout = recording.timeout;
        let order_id = params.order_id;
        let keepalive = params.keepalive;
        if (keepalive) {
          let dvr_stop = (dvr || {}).stop || '';
          let url = dvr_stop.replace(/{order_id}/, order_id || '');
          return url && await window.fetch(url, {mode: 'no-cors', keepalive});
        }
        if (!dvr.host || !dvr.stop || !recording.started || !order_id) {
          recording.started = false;
          recording.ended = false;
          recording.error = '';
          return;
        }
        let dvr_stop = (dvr.stop || '').replace(/{order_id}/, order_id || '');
        return await new Promise((resolve, reject) => {
          if (!dvr_stop) console.error('dvr_stop url missing');
          window.fetch(dvr_stop, {mode: 'no-cors'}).then(() => {
            recording.started = false;
            recording.ended = true;
            recording.error = '';
            timeout.timer.stop(false);
            resolve(true);
          }).catch(() => {
            recording.started = false;
            recording.ended = false;
            recording.error = 'end';
            timeout.timer.stop();
            this.$eventHub.$emit('ShowMessages', {
              message: 'Video recording failed to stop - Please check DVR configuration',
              type: 'error',
              hide: 2000
            });
            reject();
          });
        });
      },
      async StartPacking() {
        let value = String(this.input.order.value).trim();
        let barcode = value.replace(/\s/g, '')/*.replace(/æ|~/i, ':')*/;
        if (!barcode) return;
        /*
        if (!/^\d:\d{9}$/.test(barcode) && /^\d+$/.test(barcode) && barcode.length == 10) {
          barcode = [barcode.slice(0, 1), ':', barcode.slice(1)].join('');
        }
        */
        this.input.order.value = barcode;
        if (!Config.ValidateOrderBacode(barcode)) {
          this.scan.alert = true;
          this.$eventHub.$emit('ShowAlert', {
            title: 'Invalid order barcode', 
            message: 'The barcode format is invalid.', 
            center: true, 
            //persist: true
          });
          /*
          let keydown = {
            enter: (e) => {
              if (/enter/i.test(e.key)) {
                this.$eventHub.$emit('HideAlert');
                document.removeEventListener('keydown', keydown.enter);
                return this.scan.alert = false;
              }
            }
          }
          document.addEventListener('keydown', keydown.enter);
          */
          return this.$eventHub.$on('HideAlert', () => {
            this.scan.alert = false;
          });
        }
        this.loading = true;
        await this.CurrentPunch().then(async punch => {
          if (!punch) {
            this.loading = false;
            return this.$eventHub.$emit('ShowMessages', {
              message: 'You are not punched in',
              type: 'error',
              hide: 2000
            });
          }
          if (!await this.ForceCommissionPunch(punch)) return this.loading = false;
          if (!await this.ClosePackingOrder({barcode, seconds: this.seconds})) return this.loading = false;
          
          this.scan.status.type = 'pending';
          this.scan.status.message = 'Fetching';
          await BPA.api.GetPackingOrder(barcode).then(response => {
            return BPA.api.response({response, return: 'json', 
              error: (message) => {
                let timeout = 5000;
                const closeAlert = () => {
                  this.scan.alert = false;
                  this.input.order.value = '';
                  this.scan.status.type = 'ok';
                  this.scan.status.message = 'Ready';
                }
                if(response && response.status == 400) {
                  this.$eventHub.$emit('ShowAlert', {
                    title: 'Order not found',
                    message: 'Attempt to rescan',
                    center: true,
                  })
                }
                if(response && response.status == 409) {
                  this.$eventHub.$emit('ShowAlert', {
                  title: 'Picked in wrong order',
                  message: 'The order is not allowed to be packed yet',
                  center: true,
                  })
                }
                if (response && response.status == 423) {
                  let error_message = message.split(/ - (.+)/);
                  error_message = error_message.filter(m => m.length);
                  const lock_stringified = error_message.pop();
                  const lock = JSON.parse(lock_stringified);
                  this.scan.alert = true;
                  this.scan.status.type = 'error';
                  this.scan.status.message = 'Locked';
                  this.$eventHub.$emit('ShowAlert', {
                    title: `Locked by ${lock.res_user_full_name}`, 
                    message: `<div style="width: 400px;">
                      <div style="display: flex; flex-direction: column;">
                        <span style="user-select: none;">Note</span>
                        <textarea readonly class="alert-textarea" style="height: 5em; font-size: 1em; color: #333; padding: 7px; white-space: normal; outline: none; border-radius: 4px; border: 1px solid rgba(60, 60, 60, 0.26); resize: vertical; overflow-y: auto;">${lock.note}</textarea>
                      </div>
                    </div>`
                  });
                  this.loading = false;
                  setTimeout(() => {
                    const popup = document.querySelector('.alert-popup');
                    if (popup) {
                      const button = popup.querySelector('.alert-button');
                      ['click', 'keydown'].forEach(event => {
                        popup['on' + event] = (e) => {
                          if (e.type == 'click') {
                            if ([popup, button].includes(e.target)) {
                              closeAlert();
                            }
                          }
                          if (e.type == 'keydown') {
                            if (['Escape', 'Enter'].includes(e.key)) {
                              closeAlert();
                            }
                          }
                        }
                      });
                      popup.focus();
                    }
                  });
                  message = error_message.shift();
                } 
                else {
                  this.scan.status.type = 'error';
                  this.scan.status.message = 'Error';
                  setTimeout(closeAlert, timeout);
                }
                this.LogSeverResponse(response);
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  hide: timeout
                });
              }
            });
          }).then(async response => {
            this.loading = false;
            if (!response.ok || !response.result) return;
            let order = response.result || {};
            this.check_tnt_barcode.will_show_popup = order.check_tnt
            const order_items = [];
            const company_code = this.CompanyCode(order.company_id);
            const uid_check_list = this.uid.check.list[company_code];
            const uid_check_keys = uid_check_list ? Object.keys(uid_check_list) : [];
            const order_id = order.company_id + ':' + order.increment_id;
            barcode = order_id;
            this.uid.list = {};
            this.uid.promises = {};
            order.items.map(item => {
              let uid_check = false;
              const uid_check_barcode = item.barcode.find(barcode => uid_check_keys.some(key => key == barcode));
              const uid_check_sku = uid_check_keys.find(key => key == item.sku);
              if (uid_check_barcode || uid_check_sku) {
                this.uid.promises[item.id] = uid_check_barcode || uid_check_sku;
                this.uid.list[item.id] = [];
                uid_check = true;
              }
              if (order_items.find(prod => prod.id == item.sku)) {
                const index = order_items.findIndex(prod => prod.id == item.sku);
                order_items[index].amount += item.qty;
                order_items[index].remaining += item.qty;
                order_items[index].order_item_id[item.id] = {qty: item.qty, uid: []};
                return;
              }
              order_items.push({
                id: item.sku,
                image: item.img,
                name: item.name,
                amount: item.qty,
                ean: item.barcode,
                ok: item.barcode.filter(barcode => /ok/i.test(barcode)),
                require_password: item.require_password,
                placeholder: false,
                remaining: item.qty,
                scanned: 0,
                uid_check: uid_check,
                order_item_id: {
                  [item.id]: {
                    qty: item.qty, 
                    uid: []
                  }
                }
              });
              if(order.shipping_method.attributes.includes('swip_box')) {
                this.swip_box.is_swip_box = true
                this.swip_box.order = order
              }
            });
            this.GetOrderCompanyLogo(company_code);
            for (let prop in (order.additional || {})) {
              let additional = order.additional[prop];
              if (prop == 'order_product') {
                let additions = additional || [];
                for (let item of additions) {
                  let barcodes = String(item.barcode).split(',');
                  let addition = {
                    id: barcodes[0],
                    amount: 1,
                    name: item.name,
                    ean: barcodes,
                    ok: [],
                    require_password: false,
                    order_item_id: {
                      [barcodes[0]]: {
                        qty: 1, 
                        uid: []
                      }
                    },
                    placeholder: false,
                    remaining: 1,
                    scanned: 0,
                    uid_check: false
                  }
                  addition.image = await this.GetAdditionalOrderProductImage(item.id);
                  order_items.push(addition);
                }
              }
              if (prop == 'shipping_crate_type') {
                let crate_type = additional || {};
                if (crate_type.img) {
                  await this.GetOrderCrateTypeImage(crate_type.id);
                }
              }
            }
            if (this.OrderCrateImage == '--image: url();') {
              await this.GetOrderCrateImage(order.shipping_method.agent_code, order.shipping_description);
            }
            if (this.pin === null && order_items.some(item => item.require_password)) {
              this.pin = await this.GetPackingRequirePasswordPin();
            }
            this.order = {id: barcode, company: company_code, items: order_items, scanned: [], shipping: order.shipping_method, overview_id: order.id, order_id};
            this.scan.status.type = 'ok';
            this.scan.status.message = 'Scanning';
            this.ShowPopup();
          });
        });
        if (!await this.StartPackingOrder(barcode)) return this.loading = false;
      },
      StartCounter() {
        this.StopCounter();
        this.isRunning = true;
        this.counter = setInterval(() => {
          this.seconds++;
        }, 1000);
      },
      StopCounter() {
        clearInterval(this.counter);
        this.isRunning = false;
        this.counter = null;
        this.seconds = 0;
      },
      GetColor(seconds) {
        let percentage = seconds / this.maxSeconds * 100;
        for (let key in this.colors) {
          if (percentage < parseInt(key)) {
            return this.colors[key];
          }
        }
        return this.fallbackColor;
      },
      GetTimer(seconds) {
        return this.FormatNumber(Math.floor(seconds / 60)) + ':' + this.FormatNumber((seconds % 60));
      },
      FormatNumber(number) {
        let temp = number.toString();
        while (temp.length < 2) {
          temp = '0' + temp;
        }
        return temp;
      },
      GetOrderCompanyLogo(company_code) {
        this.OrderCompanyLogo = '--logo: url(' + (company_code ? (BPA.util.GetCompanyLogo(company_code) + '?' + new Date().getTime()) : '') + ');';
      },
      async GetOrderCrateImage(agent_code, shipping_method) {
        this.OrderCrateImage = '--image: url(' + (agent_code && shipping_method ? await this.GetShippingCrateImage({agent_code, shipping_method}) : '') + ');';
      },
      async GetOrderCrateTypeImage(type_id) {
        this.OrderCrateImage = '--image: url(' + (type_id ? await this.GetShippingCrateTypeImage(type_id) : '') + ');';
      },
      async GetAdditionalOrderProductImage(addition_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetAdditionalOrderProductImage(addition_id).then(response => {
            return BPA.api.response({response, return: 'blob', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetShippingCrateImage(request) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetShippingCrateImage(request).then(response => {
            return BPA.api.response({response, return: 'blob', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      async GetShippingCrateTypeImage(type_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetShippingCrateTypeImage(type_id).then(response => {
            return BPA.api.response({response, return: 'blob', error(){}});
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(response.result);
          }).catch(reject);
        }).catch(e => e);
      },
      GetPackages() {
        const packages = JSON.parse(BPA.storage.getItem('packages')) || [];
        const pages = Math.ceil(packages.length / this.maxListAmount);
        return {list: packages, pages: pages};
      },
      SetPage(page, direction = null) {
        if (page === null) {
          switch (direction) {
            case -1:
              if (this.currentPage > 0) {
                this.currentPage--;
              }
              break;
            case 1:
              if (this.currentPage < (this.packages.pages - 1)) {
                this.currentPage++;
              }
              break;
          }
          return;
        }
        this.currentPage = page;
      },
      GetPaginatedList() {
        let i = this.currentPage * this.maxListAmount;
        const max = this.currentPage * this.maxListAmount + this.maxListAmount;
        const buffer = [];
        while (i < max) {
          if (this.packages.list[i] !== undefined) {
            buffer.push(this.packages.list[i]);
          }
          i++;
        }
        return buffer;
      },
      /*
      HandleBlockage(event) {
        const element = event.target;
        const classNames = ['blacklist', 'button'];
        if ([...element.classList].some(className => classNames.includes(className))) {
          this.blacklist.blockage = false;
          this.MountKeyEventHandlers();
        }
      },
      */
      ApproveScan(order_item_id) {
        let error = false;
        let product_found = false;
        let product_uid_check = [];
        let product_uid_required = false;
        let required_password_pin = false;
        let barcode = this.input.product.value.trim();
        const item = this.order.items.filter(item => {
          if(barcode == 57108766) {
            barcode = 5701090038120;
          }
          if (product_found) return;
          if (item.id == barcode || item.ok.includes(barcode) || item.ean.includes(barcode)) {
            /*
            if (this.blacklist.product.box.includes(barcode)) {
              this.UnmountKeyEventHandlers();
              this.blacklist.message = `
                <h2 class="heading">Only use</h2>
                <img class="image" src="${require('@/assets/images/box4_box6.png')}">
                <h2 class="heading">to pack</h2>
                <img class="image" src="${item.image}">
              `;
              this.blacklist.blockage = true;
            }
            */
            if (Object.keys(this.uid.check.list).length) {
              if (item.uid_check) product_uid_check = [item.id, item.ean, item.ok].flat();
              if (product_uid_check.length && !Object.keys(item.order_item_id).includes(order_item_id)) {
                product_uid_required = true;
                this.UnmountKeyEventHandlers();
                this.$eventHub.$emit('ShowAlert', {
                  title: 'Input required', 
                  message: `<div style="width: 400px;">
                    <div style="display: flex; flex-direction: column;">
                      <span style="user-select: none;">Product serial number</span>
                      <input class="alert-input" style="font-size: 1em; color: #333; padding: 7px; white-space: normal; outline: none; border-radius: 4px; border: 1px solid rgba(60, 60, 60, 0.26);">
                    </div>
                  </div>`,
                  persist: true,
                  prevent: true,
                  button: 'Submit'
                });
                this.$nextTick().then(() => {
                  const popup = document.querySelector('.alert-popup');
                  const input = popup.querySelector('.alert-input');
                  const button = popup.querySelector('.alert-button');
                  button.style.backgroundColor = '#58bf5d';
                  const closeAlertPrompt = () => {
                    this.$eventHub.$emit('HideAlert');
                    setTimeout(() => {
                      this.MountKeyEventHandlers();
                    }, 100);
                  } 
                  const submitProductUID = () => {
                    let uid_check_list = this.uid.check.list[this.order.company];
                    let uid_check_keys = uid_check_list ? Object.keys(uid_check_list) : [];
                    let uid_check_regex = uid_check_keys.map(key => product_uid_check.includes(key) && uid_check_list[key]).filter(e => e)[0];
                    if (new RegExp(uid_check_regex).test(input.value)) {
                      order_item_id = Object.keys(item.order_item_id)[0];
                      item.order_item_id[order_item_id].uid.push(input.value);
                      this.uid.list[order_item_id] = item.order_item_id[order_item_id].uid;
                      if (item.order_item_id[order_item_id].qty != item.order_item_id[order_item_id].uid.length) {
                        closeAlertPrompt();
                        this.ApproveScan(order_item_id);
                        this.$eventHub.$emit('ShowMessages', {
                          message: 'Serial number added',
                          type: 'success',
                          hide: 2000
                        });
                        return;
                      }
                      this.uid.promises[order_item_id] = new Promise((resolve, reject) => {
                        BPA.api.AddUniqueIdentifierToProduct({
                          order_item_id: order_item_id,
                          identifier: item.order_item_id[order_item_id].uid.join('\n')
                        }).then(response => {
                          closeAlertPrompt();
                          return BPA.api.response({response, 
                            return: () => {
                              resolve(response);
                              this.ApproveScan(order_item_id);
                              delete item.order_item_id[order_item_id];
                              this.$eventHub.$emit('ShowMessages', {
                                message: 'Serial number added',
                                type: 'success',
                                hide: 2000
                              });
                            },
                            error: (message) => {
                              reject(message);
                              this.LogSeverResponse(response);
                              this.$eventHub.$emit('ShowMessages', {
                                message: message,
                                type: 'error',
                                close: true
                              });
                            }
                          });
                        });
                      }).catch(e => e); 
                    } else {
                      this.$eventHub.$emit('ShowMessages', {
                        message: 'Invalid serial number',
                        type: 'error',
                        hide: 2000
                      });
                      input.focus();
                      //closeAlertPrompt();
                    }
                  }
                  popup.onclick = (e) => {
                    if (e.target == popup) {
                      closeAlertPrompt();
                    }
                  }
                  input.onkeyup = (e) => {
                    if (e.key == 'Escape') {
                      closeAlertPrompt();
                    }
                    if (e.key == 'Enter') {
                      submitProductUID();
                    }
                  }
                  input.focus();
                  button.onclick = submitProductUID;
                });
                return;
              }
            }
            if (item.require_password) {
              required_password_pin = this.pin;
              if (required_password_pin) {
                this.UnmountKeyEventHandlers();
                this.$eventHub.$emit('ShowAlert', {
                  title: 'Input required', 
                  message: `<div style="width: 400px;">
                    <div style="display: flex; flex-direction: column;">
                      <span style="user-select: none;">Approval pin code</span>
                      <input class="alert-input" style="font-size: 1em; color: #333; padding: 7px; white-space: normal; outline: none; border-radius: 4px; border: 1px solid rgba(60, 60, 60, 0.26);">
                    </div>
                  </div>`,
                  persist: true,
                  prevent: true,
                  button: 'Submit'
                });
                this.$nextTick().then(() => {
                  let alert = {
                    popup: document.querySelector('.alert-popup'),
                    close: () => {
                      this.$eventHub.$emit('HideAlert');
                      setTimeout(() => {
                        this.MountKeyEventHandlers();
                      }, 100);
                    },
                    submit: () => {
                      let value = String(alert.input.value).trim();
                      if (!value.replace(/\s/g, '').length) {
                        return alert.input.focus();
                      }
                      if (value == required_password_pin) {
                        alert.close();
                        item.require_password = false;
                        if (alert.checkbox && alert.checkbox.input.checked) {
                          for (let product of this.order.items) {
                            if (product.require_password) {
                              product.require_password = false;
                            }
                          }
                        }
                        this.ApproveScan(order_item_id);
                        this.$eventHub.$emit('ShowMessages', {
                          message: 'Approval pin code accepted',
                          type: 'success',
                          hide: 2000
                        });
                      } else {
                        alert.input.focus();
                        this.$eventHub.$emit('ShowMessages', {
                          message: 'Invalid approval pin code',
                          type: 'error',
                          hide: 2000
                        });
                      }
                    }
                  };
                  alert.message = alert.popup.querySelector('.alert-message');
                  alert.input = alert.message.querySelector('.alert-input');
                  alert.action = alert.popup.querySelector('.alert-action');
                  alert.button = alert.action.querySelector('.alert-button');
                  alert.button.style.backgroundColor = '#58bf5d';
                  alert.button.onclick = alert.submit;
                  let products_requiring_password_pin = this.order.items.filter(item => item.require_password);
                  if (products_requiring_password_pin.length > 1) {
                    alert.checkbox = {
                      container: document.createElement('div'),
                      label: document.createElement('label'),
                      text: document.createElement('div'),
                      wrapper: document.createElement('div'),
                      input: document.createElement('input'),
                      toggle: document.createElement('span')
                    };
                    alert.list = document.createElement('ul');
                    alert.checkbox.container.style.display = 'flex';
                    alert.checkbox.label.className = 'label v-checkbox-label';
                    Object.assign(alert.checkbox.label.style, {
                      flexDirection: 'row-reverse', 
                      alignItems: 'center', 
                      marginTop: '20px', 
                      cursor: 'pointer'
                    });
                    alert.checkbox.text.className = 'label-text';
                    alert.checkbox.text.textContent = 'Approve all products requiring pin code';
                    alert.checkbox.wrapper.className = 'v-wrapper checkbox';
                    alert.checkbox.wrapper.style.marginRight = '10px';
                    alert.checkbox.input.type = 'checkbox';
                    alert.checkbox.input.className = 'v-checkbox';
                    alert.checkbox.input.checked = true;
                    alert.checkbox.toggle.className = 'v-checkbox-toggle';
                    Object.assign(alert.checkbox.toggle.style, {
                      width: '24px', 
                      height: '24px'
                    });
                    alert.checkbox.wrapper.append(alert.checkbox.input, alert.checkbox.toggle);
                    alert.checkbox.label.append(alert.checkbox.text, alert.checkbox.wrapper);
                    alert.checkbox.container.append(alert.checkbox.label);
                    products_requiring_password_pin.map(item => {
                      let list_item = document.createElement('li');
                      list_item.textContent = item.name;
                      alert.list.append(list_item);
                    });
                    alert.list.style.padding = '10px 0 10px 33px';
                    alert.message.append(alert.checkbox.container, alert.list);
                  }
                  alert.popup.onclick = (e) => {
                    if (e.target == alert.popup) {
                      alert.close();
                    }
                  }
                  alert.input.onkeyup = (e) => {
                    if (e.key == 'Escape') {
                      alert.close();
                    }
                    if (e.key == 'Enter') {
                      alert.submit();
                    }
                  }
                  alert.input.focus();
                });
                return;
              }
            }
            if (!error) {
              item.scanned++;
              item.remaining--;
              product_found = true;
              if (item.amount > 1) {
                let temp = this.order.scanned.find(o => o.id == item.id);
                if (!temp) {
                  temp = JSON.parse(JSON.stringify(item));
                  temp.placeholder = true;
                  this.order.scanned.push(temp);
                }
                temp.scanned = item.scanned;
              }
              clearTimeout(this.arrow.delay);
              this.arrow.classList.add('grow', item.scanned == item.amount ? 'green' : 'yellow');
              this.arrow.delay = setTimeout(() => {
                this.arrow.classList.remove('grow', 'green', 'yellow');
              }, 100);
            }
            if (item.scanned > item.amount) {
              error = true;
            }
            return item;
          }
        })[0];
        if (product_uid_required || required_password_pin) return;
        if (item) {
          if (item.scanned == item.amount) {
            this.order.items = this.order.items.filter(o => {
              if (o.id == item.id) {
                let i = this.order.scanned.findIndex(s => s.id == o.id);
                if (i > -1) {
                  this.order.scanned.splice(i, 1, o);
                } else {
                  this.order.scanned.push(o);
                }
              }
              return o.id != item.id;
            });
            if (this.order.items.length < 1) {
              const printer_id = (BPA.printer.fetch('label') || {}).id;
              const packing_station = BPA.printer.fetch('station');
              if (!barcode || !printer_id || !packing_station) {
                let error = '';
                switch (true) {
                  case !barcode:
                    error = 'Packing order barcode missing';
                    break;
                  case !printer_id:
                    error = 'Label printer not selected';
                    break;
                  case !packing_station:
                    error = 'Packing station name missing';
                }
                this.scan.status.type = 'error';
                this.scan.status.message = 'Error';
                this.$eventHub.$emit('ShowMessages', {
                  message: error,
                  type: 'error',
                  close: true
                });
                return;
              }
              Promise.allSettled(Object.values(this.uid.promises)).then(promises => {
                for (let i = 0; i < promises.length; i++) {
                  if (promises[i].status != 'fulfilled') {
                    this.$eventHub.$emit('ShowMessages', {
                      message: 'Failed to add some unique identifiers',
                      type: 'error',
                      close: true
                    });
                    console.warn('Promises unresolved!', this.uid.promises, this.order.items, this.order.scanned)
                    //console.log(this.uid.list)
                    return;
                  }
                }
                if(this.swip_box.is_swip_box == true) {
                  this.SwipBoxPopup(this.swip_box.order)
                }
                this.PrintPackageLabel({
                  order_id: this.order.id, 
                  printer_id: printer_id, 
                  packing_station: packing_station
                });
                this.check_tnt_barcode.show_popup = this.check_tnt_barcode.will_show_popup
              });
            }
          } else if (error) {
            this.$eventHub.$emit('ShowMessages', {
              message: 'Too many of this product',
              type: 'error',
              hide: 2000
            });
          }
        } else {
          if (!barcode) return;
          this.scan.alert = true;
          let title = '', message = '';
          const lookForProductIn = (list) => {
            return list.find(item => item.ok.includes(barcode) || item.ean.includes(barcode));
          }
          if (!lookForProductIn(this.order.items) && !lookForProductIn(this.order.scanned)) {
            title = 'Product error';
            message = 'This product is not in the order!';
          }
          if (lookForProductIn(this.order.scanned)) {
            title = 'Product overflow';
            message = 'Too many of this product.\nCheck the order!';
          }
          this.$eventHub.$emit('ShowAlert', {
            title: title, message: message, center: true, /*persist: true*/
          });
          this.$eventHub.$on('HideAlert', () => this.scan.alert = false);
        }
      },
      async PrintPackageLabel(params = {}) {
        this.scan.status.type = 'pending';
        this.input.order.value = '';
        this.scan.status.message = 'Sending';
        if (this.AllowDisableCache() && this.disable_cache) {
          params.disable_cache = this.disable_cache;
        }
        this.loading = true;
        return await BPA.api.PrintPackageLabel(params).then(response => {
          this.loading = false;
          this.disable_cache = false;

          //set label scan into focus.
          let tnt_barcode_input = document.getElementsByClassName('tnt-check-input')
          if(tnt_barcode_input[0]) {
            tnt_barcode_input[0].focus()
          }

          return BPA.api.response({response, 
            return: () => {
              this.scan.status.type = 'ok';
              this.scan.status.message = 'Printing';
              setTimeout(() => {this.scan.status.message = 'Ready'}, 3000);
              let previous_order = {barcode: this.order.id, id: this.order.overview_id};
              this.SavePackage(previous_order, this.seconds, this.order.scanned);
              this.ClosePopup(true);
            },
            error: (message) => {
              //this.StopVideoRecording({order_id: this.order.overview_id});
              this.StopCounter();
              this.ClosePopup(false);
              this.scan.status.type = 'error';
              this.scan.status.message = 'Error';
              setTimeout(() => {
                this.scan.status.type = 'ok';
                this.scan.status.message = 'Ready';
              }, 3000);              
              this.LogSeverResponse(response);
              this.$eventHub.$emit('ShowMessages', {
                message: message,
                type: 'error',
                close: true
              });
            }
          });
        });
      },
      async ClosePackingOrder(params) {
        return await new Promise(async (resolve, reject) => {
          let request = {};
          let previous = this.previous;
          let previous_id = this.previous?.id || this.order?.overview_id;
          let previous_barcode = this.previous?.barcode || this.order?.id;
          let close_previous_order = previous.barcode && previous.barcode != params.barcode;
          if (!close_previous_order) return resolve(true);
          if (params.keepalive) request.keepalive = true;
          request.order_id = previous_id;
          await this.StopVideoRecording(request);
          request.order_id = previous_barcode;
          //console.log('ClosePackingOrder', this.CloneObject(request))
          BPA.api.ClosePackingOrder(request).then(response => {
            return BPA.api.response({response, 
              return: async () => {
                const scanned = [];
                const packages = JSON.parse(BPA.storage.getItem('packages'));
                packages.forEach(package_order => {
                  if (package_order.id == previous_barcode) {
                    package_order.seconds = params.seconds;
                  }
                  scanned.push(package_order);
                });
                BPA.storage.setItem('packages', JSON.stringify(scanned));
                this.packages = this.GetPackages();
                //this.SetPage(this.packages.pages - 1);
                this.SetPage(0);
                resolve(response);
              },
              error: async (message) => {
                this.LogSeverResponse(response);
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  hide: 2000
                });
                reject(message);
              }
            });
          });
        }).catch(e => e);
      },
      SavePackage(previous_order, seconds, scanned) {
        this.previous = previous_order;
        let order_id = previous_order.barcode;
        if (BPA.storage.getItem('packages') == null) {
          BPA.storage.setItem('packages', JSON.stringify(new Array()));
        }
        let packages = JSON.parse(BPA.storage.getItem('packages'));
        if (packages.map(order => order.id).includes(order_id)) {
          packages.forEach(order => {
            if (order.id == order_id) {
              order.seconds = seconds;
              order.scanned = scanned.reduce((a, b) => a + (b.amount || 0), 0)
            }
          });
        } else {
          packages.unshift({
            id: order_id,
            seconds: seconds,
            scanned: scanned.reduce((a, b) => a + (b.amount || 0), 0)
          });
        }
        BPA.storage.setItem('packages', JSON.stringify(packages));
      },
      ShowPopup() {
        this.popup.classList.add('visible');
        document.activeElement.blur();
        this.scan.show.hidden = false;
        this.scan.show.sku = false;
        this.scan.show.su = false;
        this.isScanning = true;
        // Hide error message top bar when a new order is scanned
        const message = document.querySelector('.messages-wrapper');
        if (message && message.classList.contains('message-error')) {
          if (this.recording.error != 'start') this.$eventHub.$emit('HideMessages');
        }
        // Don't reset the counter if the order has been scanned complete and the counter is running
        if (this.GetPackages().list.find(order => order.id == this.order.id) && this.isRunning) return;
        this.StartCounter();
      },
      async ClosePopup(e) {
        /* For testing only
        this.loading = true;
        await this.StopVideoRecording();
        this.loading = false;
        */
        const reset = (error) => {
          this.OrderCrateImage = '--image: url();';
          this.popup.classList.remove('visible');
          this.input.product.value = '';
          this.input.order.value = '';
          this.isScanning = false;
          this.uid.promises = {};
          this.uid.list = {};
          if (error) return;
          this.scan.status.type = 'ok';
          this.scan.status.message = 'Ready';
        }
        if (e) {
          if (e.type == 'click') {
            /validate|close/.test(e.target.className) && reset();
          } else {
            reset(!/key/.test(e.type));
          }
        } else {
          reset(true);
        }
      },
      DblClick(barcode) {
        let barcode_input = this.$refs.barcode_input;
        if (!barcode || !barcode_input) return;
        barcode_input.value = barcode;
        this.ApproveScan();
      },
      OneClickComplete() {
        if (this.order.bypass_barcode_check) return;
        BPA.api.BypassBarcodeCheck(this.order.id).then(response => {
          return BPA.api.response({response, error(){}});
        }).then(() => this.order.bypass_barcode_check = true);
        if (!this.order.items.length && !this.order.scanned.length) {
          let printer_id = (BPA.printer.fetch('label') || {}).id;
          let packing_station = BPA.printer.fetch('station');
          if (!printer_id || !packing_station) {
            let error = '';
            switch (true) {
              case !printer_id:
                error = 'Label printer not selected';
                break;
              case !packing_station:
                error = 'Packing station name missing';
            }
            this.scan.status.type = 'error';
            this.scan.status.message = 'Error';
            this.$eventHub.$emit('ShowMessages', {
              message: error,
              type: 'error',
              close: true
            });
            return;
          }
          this.PrintPackageLabel({
            order_id: this.order.id, 
            printer_id: printer_id, 
            packing_station: packing_station
          });
          return;
        }
        let list = document.querySelectorAll('.check-list.to-do .dblclick');
        for (let i = 0; i < list.length; i++) {
          let row = list[i].closest('.row-name');
          let qty = row.firstChild.firstChild.textContent;
          for (let l = 0; l < qty; l++) {
            list[i].dispatchEvent(new Event('dblclick'));
            if (/*this.blacklist.blockage
            ||*/ Object.keys(this.uid.promises).length 
            || document.querySelector('.alert-popup')) {
              break;
            }
          }
        }
      },
      PrintScannerBarcodes(scanner_model = '') {
        if (!scanner_model) return;
        let origin = String(window.location.origin).replace(/\/$/, '');
        BPA.api.print(`${origin}/${scanner_model}.pdf?${new Date().getTime()}`);
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      AllowDisableCache() {
        return this.Check(['admin', 'scan_admin']);
      },
      SwipBoxPopup(order) {
        let shippingAddress = `${order.shipping_address.street_address} - ${order.shipping_address.zip_code}, ${order.shipping_address.city}`
        let shippingRecipient = `${order.shipping_address.first_name} ${order.shipping_address.last_name}`

        let imgPath = this.OrderCrateImage.substring(9)
        imgPath = 'content: ' + imgPath

        const enterEvent = new KeyboardEvent('keydown', {
          key: 'Enter',
          code: 'Enter',
          which: 13,
          keyCode: 13,
        });

        this.$eventHub.$emit('ShowAlert', {
          title: `${this.$t('Swip Box')} - ${order.shipping_description}`,
          message: `<div id="work-done-report" style="width: 500px; text-align: center;">
            <div style="display: flex; width: 100%; height: 500px; margin-bottom: 10px; border-bottom: 2px solid lightgray; align-items: center;">
              <img width="100%" height="400px" style="${imgPath}"/>
            </div>
            <div style="display: flex; flex-direction: column; width: 100%; font-weight: 600; font-size: 22px; line-height: 1; color: #1B577A;">
              <div style="width: 100%;">${this.$t('Shipping address')}: ${shippingAddress}</div>
              <div style="width: 100%; margin-top: 10px;">${this.$t('Shipping Recipient')}: ${shippingRecipient}</div>
            </div>
          </div>`
        });

        document.addEventListener('keyup', document.fn = function fn(e) {
          if(e.keyCode == 13) {
            document.dispatchEvent(enterEvent)
            document.removeEventListener('keyup', document.fn, false)
          }
        })
        this.swip_box.is_swip_box = false
      },
      async MatchTnTBarcode() {
        if(this.check_tnt_barcode.barcode == '12A,4dqa.&U496kDd') {
          this.ResetTntCheck()
          return
        }

        let params = {
          overview_id: this.order.overview_id,
          barcode: this.check_tnt_barcode.barcode
        }

        const stop_scan = Date.now()

        if((stop_scan - this.check_tnt_barcode.start_scan) < 2000) {
          const barcode_check_response = await BPA.api.CheckTnTBarcode(params).then((response) => {
            return response.json() 
          })
  
          this.check_tnt_barcode.is_correct = barcode_check_response.status
  
          if(this.check_tnt_barcode.is_correct) {
            this.ResetTntCheck()
          }
          else {
            this.check_tnt_barcode.error.active = true
            this.check_tnt_barcode.error.message = 'Wrong label scanned'

            this.check_tnt_barcode.barcode = ''
            this.$refs.tntCheckInput.addEventListener('keydown', this.HandleTnTBarcodePress)
          }
        }
        else {
          this.check_tnt_barcode.error.active = true
          this.check_tnt_barcode.error.message = 'SCAN the barcode'

          this.check_tnt_barcode.barcode = ''
          this.$refs.tntCheckInput.addEventListener('keydown', this.HandleTnTBarcodePress)
        }
      },
      ResetTntCheck() {
        this.check_tnt_barcode.show_popup = false
        this.check_tnt_barcode.barcode = ''
        this.check_tnt_barcode.error.active = false
        this.check_tnt_barcode.will_show_popup = false
      },
      HandleTnTBarcodePress() {
        this.check_tnt_barcode.start_scan = Date.now()
        this.$refs.tntCheckInput.removeEventListener('keydown', this.HandleTnTBarcodePress)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/style/variables/colors';

  .log {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.15);

    &-popup {
      z-index: 1;
      min-width: 720px;
      max-width: 1080px;
      position: relative;
      border-radius: 10px;
      padding: 20px;
      background-color: $white;

      &__top, &__content {
        display: flex;
        position: relative;
      }

      &__top + &__content {
        margin-top: 15px;
      }

      &__top {
        user-select: none;
        align-items: center;
        justify-content: space-between;

        &--heading {

        }

        &--close-btn {
          width: 20px;
          height: 20px;
          display: flex;
          cursor: pointer;
          position: relative;
          align-items: center;
          justify-content: center;

          &:before, 
          &:after {
            width: 100%;
            height: 3px;
            content: '';
            position: absolute;
            background-color: #606060;
          }

          &:before {
            transform: rotate(45deg);
          }

          &:after {
            transform: rotate(-45deg);
          }
        }
      }

      &__content {
        padding: 20px;
        min-height: 300px;
        max-height: 600px;
        overflow-y: auto;
        //overflow-x: hidden;
        border-radius: 5px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.05);
        //border: 1px solid rgba(60, 60, 60, 0.25);
      }
    }
  }

  .grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }
  .tnt-check-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .tnt-check-container {
    padding: 20px;
    min-width: 325px;
    max-width: 650px;
    border-radius: 10px;
    background-color: #ffffff;
    transition: all .2s ease-in-out;
  }
  .tnt-check-heading {
    font-size: 2rem;
    text-align: center;
  }
  .tnt-check-error {
    font-size: 1.5rem;
    color: red;
    margin-block: 0.75rem;
    text-align: center;
  }
  .tnt-check-input {
    width: 450px;
    height: 45px;
    border: 2px solid #a6afb7;
    border-radius: 5px 5px 0 0;
    outline: none;
    padding-left: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1.111rem;
    color: #606060;
    text-align: center;
  }
</style>